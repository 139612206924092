import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"

const SubscribedPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Newlsetter Subscription" />
      <div className="page-headline">
        <div className="container">
          <h1 className="font-weight-bold text-uppercase text-primary d-inline-block border-bottom" style={{ fontFamily: 'Roboto, serif', fontSize: '37px', lineHeight: '64.75px', margin: 0 }}>
            Subscribed
          </h1>
          <p className="lead">
            Thank you for subscribing to our newsletter!
            <div className="mt-4">
              <Link to="/" className="btn btn-sm btn-primary">
                Return Home
              </Link>
            </div>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SubscribedPage
